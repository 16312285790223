import { Fragment } from 'react';
import { Container } from '@mui/material';
import { Snackbar } from 'style-guide';
import MainPage from 'MainPage';
import { useSelector, useDispatch } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import Login from './components/login/Login';
import CustomerLogin from './components/customer/login/CustomerLogin';
import { Routes, Route } from 'react-router-dom';
import './App.scss'
const App = () => {
  const dispatch = useDispatch();
  const { snackbar } = useSelector(state => state.snackbar);
  const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
  const hostname = window.location.hostname;
  return (
    <Fragment>
      {/*<Container maxWidth='false'>*/}
        <Routes>
          <Route exact path='/*' element={<MainPage />} />
              {hostname === "localhost" ? <Route exact path='/login' element={<Login />} />: null}
          {hostname === "localhost-customer" ?<Route exact path='/login' element={<CustomerLogin />} />: null}
          {hostname === "agent.tclog.co.il" ?<Route exact path='/login' element={<Login />} />: null}
          {hostname === "agent.il" ?<Route exact path='/login' element={<Login />} />: null}
          {hostname === "freight-analytics.com" ?<Route exact path='/login' element={<CustomerLogin />} />: null}
          {hostname === "fa1.com" ?<Route exact path='/login' element={<CustomerLogin />} />: null}
        </Routes>
     {/* </Container>*/}
      {snackbar && (
        <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} autoHideDuration={snackbar.autoHideDuration} customIcon={snackbar.customIcon}/>
      )}
    </Fragment>
  );
};

export default App;
